<template>
  <div class="contract">
    <center-title title="合同管理" :border="false"></center-title>
    <el-form ref="form" :inline="true" :model="ruleForm" label-width="0px">
      <el-form-item>
        <el-input
          v-model="ruleForm.contract_number"
          placeholder="请输入编号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="ruleForm.contract_name"
          placeholder="请输入合同名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="ruleForm.sign_date"
          type="date"
          placeholder="请选择签订日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="reset"
          class="lighter submit"
          @click="getContractLists(true)"
          >订单搜索</el-button
        >
      </el-form-item>
    </el-form>
    <el-table :data="contractList">
      <el-table-column align="center" prop="contract_number" label="合同编号">
      </el-table-column>
      <el-table-column align="center" prop="contract_name" label="合同名称">
      </el-table-column>
      <el-table-column align="center" prop="id" label="订单号">
      </el-table-column>
      <el-table-column
        class-name=" price"
        align="center"
        prop="contract_price"
        label="价格"
      >
      </el-table-column>
      <el-table-column align="center" prop="contract_deadline" label="到期天数">
      </el-table-column>
      <el-table-column align="center" prop="party_b_status" label="合同状态">
        <template slot-scope="scope">
          <span v-if="scope.row.party_b_status == 0">待签署</span>
          <span v-if="scope.row.party_b_status == 1">已签署</span>
          <span v-if="scope.row.party_b_status == 2">已拒签</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="sign_date" label="签订日期">
      </el-table-column>
      <el-table-column align="center" prop="party_b_status" label="操作">
        <template slot-scope="scope">
          <span
            v-if="scope.row.party_b_status == 0"
            class="price cursor"
            @click="toSign(scope.row.id)"
            >签署</span
          >
          <span
            v-if="scope.row.party_b_status == 1"
            class="cursor"
            @click="toSee(scope.row.id)"
            >查看</span
          >
          <span v-if="scope.row.party_b_status == 2" class="cursor"
            >已拒签</span
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      ruleForm: {},
      contractList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    async getContractLists(condition = false) {
      let { code, data } = await this.$api.getContractListsApi({
        ...this.ruleForm,
      });
      if (code == 1) {
        if (condition) {
          this.$message({
            message: "获取成功",
            type: "success",
          });
        }
        this.contractList = data;
      }
    },
    toSign(id) {
      this.$api
        .getSignedLinkApi({
          id: id,
        })
        .then((res) => {
          if (res.code == 1) {
            window.open(res.data.url, "_blank");
          }
        });
    },
    toSee(id) {
      this.$api
        .getContractViewApi({
          id,
        })
        .then((res) => {
          if (res.code == 1) {
            window.open(res.data.url, "_blank");
          }
        });
    },
  },
  created() {
    this.getContractLists();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.contract {
  width: 890px;
  /deep/ .el-form {
    display: flex;
    justify-content: space-between;
    .el-form-item {
      margin-right: 0;
      .el-input__inner {
        height: 45px;
        border: 2px solid $-color-border;
        border-radius: 0;
      }
    }
    .el-input__icon {
      line-height: 45px;
    }
    .el-input {
      width: 254px;
      margin-right: 0;
    }
    .el-button {
      width: 107px;
      height: 45px;
      background: $-color-border;
      border: 2px solid $-color-border;
      font-size: 16px;
      border-radius: 0;
      padding: 0;
      border: none;
      &:hover {
        color: $-color-white;
        background-color: $-color-primary;
      }
    }
  }
  /deep/ .el-table {
    border: $-solid-border;
    font-size: 16px;
    border-bottom: none;
    .el-table__cell {
      padding: 15px 0;
    }
    th {
      background-color: $-color-center;
      .cell {
        font-size: 18px;
        color: $-color-lighter;
        font-weight: normal;
      }
    }
    .table-item {
      color: $-color-muted;
    }
    tr:hover > td {
      background-color: transparent;
    }
  }
}
</style>
